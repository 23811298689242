import { endOfDay, startOfDay } from 'date-fns'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '../../api'
import { Game } from '../../features/game/types/Game'
import { SearchedGame } from '../../features/live-events/types/SearchedGame'
import { TrackedGame } from '../../features/live-events/types/TrackedGame'
import { getUTCDate } from '../../helpers/date'
import { Analyst } from '../../types/Analyst'
import { Setting } from '../../types/Setting'
import { pricePointDefaultCurrency } from '../features/live-events-tracking/components/PricePointContainer/PricePointSelectMenu/PricePointSelectMenu'
import { PricePointMutation } from '../features/live-events-tracking/types/PricePoint'
import { SettingService, SettingKey } from '../features/settings/types/types'
import { GameMutation } from '../types/GameMutation'
import { InternalAnalystComment } from '../types/InternalAnalystComment'
import { InternalAnalystNote } from '../types/InternalAnalystNotes'
import { InternalEventOverview } from '../types/InternalEventOverview'
import { InternalTrackedGameReport } from '../types/InternalTrackedGameReport'
import { InternalDuration, InternalTrackingEvent, InternalTrackingEventByDate } from '../types/InternalTrackingEvent'
import { InternalTrackingEventEvent, InternalTrackingEventMutation } from '../types/InternalTrackingEvent'

export const internalCoreApi = createApi({
  reducerPath: 'internalCoreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_CORE + '/i/',
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: [
    'trackedGameEventsByDate',
    'trackedGameAnalystComments',
    'trackedGame',
    'trackedGameEventById',
    'trackedGameEventsByAllDates',
    'trackedGameEventsByGameId',
  ],
  endpoints: (builder) => ({
    getSetting: builder.query<Setting, { service: SettingService; settingKey: SettingKey }>({
      query: ({ service, settingKey }) => ({
        url: `settings/${service}/${settingKey}`,
        method: 'GET',
      }),
    }),
    getTrackedGame: builder.query<TrackedGame & { rawGame: Game }, string>({
      query: (id) => ({
        url: `games/tracked/${id}`,
        method: 'GET',
      }),
      transformResponse: (trackedGame: TrackedGame) => {
        return {
          ...trackedGame,
          game: new Game(trackedGame.game),
          rawGame: trackedGame.game,
        }
      },
      providesTags: () => [{ type: 'trackedGame' }],
    }),
    getTrackedGames: builder.query<TrackedGame[], void>({
      query: () => ({
        url: 'games/tracked/list',
        method: 'POST',
        body: {},
      }),
      transformResponse: (trackedGames: TrackedGame[]) => {
        return trackedGames.map((trackedGame) => {
          return {
            ...trackedGame,
            game: new Game(trackedGame.game),
          }
        })
      },
    }),
    getTrackedGameEvents: builder.query<InternalTrackingEventEvent[], string>({
      query: (trackedGameId) => ({
        url: `games/tracked/${trackedGameId}/events`,
        method: 'GET',
      }),
      providesTags: (result, error, trackedGameId) => [{ type: 'trackedGameEventsByGameId', id: trackedGameId }],
    }),
    getTrackedGameEventsByDate: builder.query<InternalTrackingEventByDate[], { trackedGameId: string; date: number }>({
      query: ({ trackedGameId, date }) => ({
        url: `games/tracked/${trackedGameId}/events/date/${startOfDay(getUTCDate(date)).getTime()}`,
        method: 'GET',
      }),
      providesTags: (result, error, { trackedGameId, date }) => [
        { type: 'trackedGameEventsByDate', id: `${trackedGameId}-${date}` },
        { type: 'trackedGameEventsByAllDates', id: trackedGameId },
      ],
    }),
    getAnalystsList: builder.query<Analyst[], void>({
      query: () => ({
        url: `analysts/list`,
        method: 'GET',
      }),
    }),
    getSearchGames: builder.query<SearchedGame[], { term: string; marketIso: string }>({
      query: ({ term, marketIso }) => ({
        url: 'games/tracked/search',
        method: 'POST',
        body: { term, marketIso },
      }),
      transformResponse: (searchedGames: SearchedGame[]) => {
        return searchedGames.map((searchedGame) => {
          return {
            ...searchedGame,
            game: new Game(searchedGame.game),
          }
        })
      },
    }),
    trackNewGame: builder.mutation<TrackedGame, { gameId: string; marketIso: string }>({
      query: ({ gameId, marketIso }) => ({
        url: 'games/tracked',
        method: 'POST',
        body: { gameId, marketIso },
      }),
    }),
    cloneEvents: builder.mutation<
      InternalTrackingEventByDate[],
      { gameId: string; sourceDate: { start: number; end: number }; targetDate: { start: number; end: number } }
    >({
      query: ({ gameId, sourceDate, targetDate }) => ({
        url: `games/tracked/${gameId}/events/clone`,
        method: 'POST',
        body: {
          start: startOfDay(getUTCDate(sourceDate.start)).getTime(),
          end: endOfDay(getUTCDate(sourceDate.end)).getTime(),
          targetStart: startOfDay(getUTCDate(targetDate.start)).getTime(),
          targetEnd: endOfDay(getUTCDate(targetDate.end)).getTime(),
        },
      }),
      invalidatesTags: (result, error, params) => [{ type: 'trackedGameEventsByDate', id: `${params.gameId}-${params.targetDate.start}` }],
    }),
    deleteTrackedGameEventByDate: builder.mutation<{ gameId: string }, { trackingEventId: string; date: number }>({
      query: ({ trackingEventId, date }) => ({
        url: `games/tracked/events/${trackingEventId}/date/${startOfDay(getUTCDate(date)).getTime()}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { trackingEventId, date }) => [{ type: 'trackedGameEventsByDate', id: `${result?.gameId}-${date}` }],
    }),
    getTrackedGameAnalystComments: builder.query<InternalAnalystNote[], { trackedGameId: string }>({
      query: ({ trackedGameId }) => ({
        url: `games/tracked/comment/game/${trackedGameId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'trackedGameAnalystComments' }],
    }),
    deleteTrackedGameNoteById: builder.mutation<{ gameId: string }, { noteId: string }>({
      query: ({ noteId }) => ({
        url: `games/tracked/comment/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'trackedGameAnalystComments' }],
    }),
    getCommentTracked: builder.mutation<InternalAnalystNote, Pick<InternalAnalystComment, 'id' | 'active' | 'comment' | 'start' | 'end' | 'gameId' | 'appId'>>({
      query: (body) => ({
        url: `games/tracked/comment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'trackedGameAnalystComments' }],
    }),
    updateTrackedGameSaasStatus: builder.mutation<TrackedGame, { gameId: string | undefined; published: boolean | undefined }>({
      query: ({ gameId, published }) => ({
        url: `games/tracked/publish`,
        method: 'PUT',
        body: { gameId, published },
      }),
      invalidatesTags: () => [{ type: 'trackedGame' }],
    }),
    getTrackedGameReports: builder.query<InternalTrackedGameReport[], void>({
      query: () => ({
        url: 'reports',
        method: 'GET',
      }),
    }),
    updateTrackedGameReview: builder.mutation<TrackedGame, Pick<InternalEventOverview, 'gameId' | 'review'>>({
      query: (body) => ({
        url: 'games/tracked/review',
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'trackedGame' }],
    }),
    updateGame: builder.mutation<Game, GameMutation>({
      query: (body) => ({
        url: `games/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'trackedGame' }],
    }),
    getTrackedGameEvent: builder.query<InternalTrackingEvent, string>({
      query: (id) => ({
        url: `games/tracked/events/${id}`,
        method: 'GET',
      }),
      transformResponse: (event: InternalTrackingEvent) => {
        return {
          ...event,
          event: {
            ...event.event,
            pricePoints: event.event.pricePoints.map((pricePoint) => {
              const id = pricePoint.currencyId || pricePointDefaultCurrency
              return {
                value: pricePoint.value,
                currencyId: id,
              }
            }),
          },
        }
      },
      providesTags: (result, error, eventId) => [{ type: 'trackedGameEventById', id: eventId }],
    }),
    updateTrackedGameEvent: builder.mutation<InternalTrackingEvent, InternalTrackingEventMutation>({
      query: (event) => ({
        url: 'games/tracked/events',
        method: event.event.id ? 'PUT' : 'POST',
        body: {
          ...event,
          event: {
            ...event.event,
            pricePoints: event.event.pricePoints.map<PricePointMutation>((pricePoint) => {
              if (pricePoint.currencyId === 'usd' || pricePoint.currencyId === '') return { value: pricePoint.value }
              return {
                value: pricePoint.value,
                currencyId: pricePoint.currencyId,
              }
            }),
          },
        },
      }),
      invalidatesTags: (result, error, { event }) => [
        { type: 'trackedGameEventById', id: event.id },
        { type: 'trackedGameEventsByAllDates', id: event.gameId || '' },
        { type: 'trackedGameEventsByGameId', id: event.gameId || '' },
      ],
    }),
    updateTrackedGameEventDurations: builder.mutation<InternalTrackingEventByDate, InternalDuration>({
      query: (body) => ({
        url: `games/tracked/events/${body.liveEventId}`,
        method: 'POST',
        body: { start: body.start, end: body.end },
      }),
      invalidatesTags: () => [{ type: 'trackedGameEventById' }],
    }),
    deleteTrackedGameEvent: builder.mutation<string, { eventId: string; gameId: string }>({
      query: ({ eventId, gameId }) => ({
        url: `/games/tracked/events/${eventId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { eventId, gameId }) => [
        { type: 'trackedGameEventById', id: eventId },
        { type: 'trackedGameEventsByAllDates', id: gameId },
        { type: 'trackedGameEventsByGameId', id: gameId },
      ],
    }),
  }),
})

export const {
  useGetTrackedGamesQuery,
  useGetTrackedGameQuery,
  useGetSettingQuery,
  useGetTrackedGameEventsQuery,
  useGetTrackedGameEventsByDateQuery,
  useGetAnalystsListQuery,
  useGetSearchGamesQuery,
  useTrackNewGameMutation,
  useCloneEventsMutation,
  useDeleteTrackedGameEventByDateMutation,
  useGetTrackedGameAnalystCommentsQuery,
  useDeleteTrackedGameNoteByIdMutation,
  useGetCommentTrackedMutation,
  useUpdateTrackedGameSaasStatusMutation,
  useGetTrackedGameReportsQuery,
  useUpdateTrackedGameReviewMutation,
  useUpdateGameMutation,
  useGetTrackedGameEventQuery,
  useLazyGetTrackedGameEventQuery,
  useUpdateTrackedGameEventMutation,
  useUpdateTrackedGameEventDurationsMutation,
  useDeleteTrackedGameEventMutation,
} = internalCoreApi
