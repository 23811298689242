import { useMemo } from 'react'

import { isNil } from '../../../helpers/isNil'
import { useSearchParams } from '../../../hooks/useSearchParams'
import { LiveEventCalendarAdditionalDataId } from '../types/LiveEventAdditionalCalendarData'
import { PerformanceChangesByOptionValue } from '../types/PerformanceChangesByOption'
import { defaultPerformanceChangesByValue } from './usePerformanceChangesByOptions'
import { SortGamesByOptionValue } from './useSortGamesBySelectOptions'
import { defaultPerformanceEffectThresholdValue } from './useThresholdOptions'

type SearchParamSource = {
  subpage: string | undefined
  gameIds: string | string[]
  liveEventTags: string[]
  liveEventSecondaryTags: string[]
  liveEventDurations: string[]
  liveEventAppearances: string[]
  additionalDatas?: string[] | emptyArray
  sortGamesBy: string
  dateFrom?: string
  dateTo?: string
  performanceEffectThreshold: string
  performanceChangesBy: PerformanceChangesByOptionValue
  feedDateFrom?: string
  feedDateTo?: string
  motivations: string[]
  archetypes: string[]
}

type SearchParamDestination = {
  subpage: string | undefined
  gameIds: string[]
  liveEventTags: string[]
  liveEventSecondaryTags: string[]
  liveEventDurations: string[]
  liveEventAppearances: string[]
  additionalDatas?: string[] | emptyArray
  sortGamesBy: SortGamesByOptionValue
  dateFrom?: number
  dateTo?: number
  performanceEffectThreshold: number
  performanceChangesBy: PerformanceChangesByOptionValue
  feedDateFrom?: number
  feedDateTo?: number
  motivations: string[]
  archetypes: string[]
}

const emptyArrayValue = 'no-selection'
type emptyArray = typeof emptyArrayValue

export const useLiveEventsTrackingSearchParams = () => {
  const mapper = useMemo(
    () => ({
      mapFromUrl: (source: SearchParamSource): SearchParamDestination => {
        return {
          ...source,
          gameIds: Array.isArray(source.gameIds) ? source.gameIds : source.gameIds ? [source.gameIds] : [],
          liveEventTags: source.liveEventTags || [],
          liveEventSecondaryTags: source.liveEventSecondaryTags || [],
          liveEventDurations: source.liveEventDurations || [],
          liveEventAppearances: source.liveEventAppearances || [],
          additionalDatas:
            source.additionalDatas === emptyArrayValue
              ? []
              : !source.additionalDatas
              ? Object.values(LiveEventCalendarAdditionalDataId)
              : source.additionalDatas,
          sortGamesBy: (source.sortGamesBy as SortGamesByOptionValue) || SortGamesByOptionValue.SustainedGrossingRank,
          dateFrom: isNumeric(source.dateFrom) ? +(source.dateFrom as string) : undefined,
          dateTo: isNumeric(source.dateTo) ? +(source.dateTo as string) : undefined,
          performanceEffectThreshold: +source.performanceEffectThreshold || defaultPerformanceEffectThresholdValue,
          performanceChangesBy: (source.performanceChangesBy || defaultPerformanceChangesByValue) as PerformanceChangesByOptionValue,
          feedDateFrom: isNumeric(source.feedDateFrom) ? +(source.feedDateFrom as string) : undefined,
          feedDateTo: isNumeric(source.feedDateTo) ? +(source.feedDateTo as string) : undefined,
          motivations: source.motivations || [],
          archetypes: source.archetypes || [],
        }
      },
      mapToUrl: (destination: SearchParamDestination): SearchParamSource => {
        return {
          ...destination,
          gameIds: destination.gameIds || [],
          liveEventTags: destination.liveEventTags || [],
          liveEventDurations: destination.liveEventDurations || [],
          liveEventAppearances: destination.liveEventAppearances || [],
          additionalDatas: destination.additionalDatas?.length === 0 ? emptyArrayValue : destination.additionalDatas,
          sortGamesBy: destination.sortGamesBy,
          dateFrom: destination.dateFrom ? destination.dateFrom + '' : undefined,
          dateTo: destination.dateTo ? destination.dateTo + '' : undefined,
          performanceEffectThreshold: destination.performanceEffectThreshold + '',
          performanceChangesBy: destination.performanceChangesBy,
          feedDateFrom: destination.feedDateFrom ? destination.feedDateFrom + '' : undefined,
          feedDateTo: destination.feedDateTo ? destination.feedDateTo + '' : undefined,
          motivations: destination.motivations || [],
          archetypes: destination.archetypes || [],
        }
      },
    }),
    []
  )

  return useSearchParams<SearchParamSource, SearchParamDestination>(mapper)
}

const isNumeric = (str?: string) => !isNil(str) && !isNaN(+(str as string)) && !isNaN(parseFloat(str as string))
