// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import { SubgenreMap } from '../features/account'
import { UserLanguage } from '../features/account/types/User'
import { ActiveUsersData } from '../features/active-users/types/ActiveUsersData'
import { Analysis, UpdateAnalysisAnswerPayload } from '../features/analysis/types/Analysis'
import { AnalysisNote, AnalysisNotes } from '../features/analysis/types/AnalysisNotes'
import { SearchArtistQueryParams } from '../features/artist-search/types/SearchArtistQueryParams'
import { Bookmark } from '../features/bookmarks/types/Bookmark'
import { BookmarksData } from '../features/bookmarks/types/BookmarksData'
import { Concept } from '../features/concepts/types/Concept'
import { EstimatePlatformType } from '../features/estimates/types/EstimatePlatformType'
import { EstimatesList } from '../features/estimates/types/EstimatesList'
import featureService from '../features/feature/services/FeatureService'
import { TopFeature } from '../features/feature/types/top-feature'
import { FeatureChoice } from '../features/game-features/types/types'
import { SearchGamesQueryParams } from '../features/game-search/types/SearchGamesQueryParams'
import { Game, GenreInnovatorGame, NewGameMetaData } from '../features/game/types/Game'
import { GameAnalysisSuggestion } from '../features/game/types/GameAnalysisSuggestion'
import { GameAndAnalysis } from '../features/game/types/GameAndAnalysis'
import { GameReview } from '../features/game/types/GameReview'
import { ConventionalCategory, ConventionalGenre, ConventionalSubgenre } from '../features/genre-taxonomy'
import { TrackedGame } from '../features/live-events/types/TrackedGame'
import { LiveEventReview, LiveTrackingEvent, TrackingEventByGame, TrackingEventsByGame } from '../features/live-events/types/TrackingEvents'
import { DataFilters } from '../features/market-explorer/types/MarketExplorerDataFilters'
import { MarketExplorerSegmentData, MarketExplorerSegmentDataFeature } from '../features/market-explorer/types/MarketExplorerSegmentData'
import { Market } from '../features/markets'
import { ProfileMotivation } from '../features/motivations/types/ProfileMotivation'
import { Store } from '../features/store'
import { UpdateImpact } from '../features/update-impacts'
import { UploadedFile } from '../internal/types/UploadedFile'
import languageService from '../services/LanguageService'
import motivationService from '../services/MotivationService'
import { Analyst } from '../types/Analyst'
import { AnalystComment } from '../types/AnalystComment'
import { FeaturesEffect } from '../types/FeatureEffects'
import { GameFilter } from '../types/GameFilter'
import { LanguageMap } from '../types/LanguageMap'
import { MongoDBObject } from '../types/MongoDBObject'
import { Setting } from '../types/Setting'
import { VersionInfo } from '../types/VersionInfo'

export type GetGameQueryParams = {
  id: string
  marketIso: string
  gameOnly?: boolean
  tags: boolean
  userLanguage: UserLanguage
}

type GetGamesQueryParams = {
  ids: string[]
  userLanguage: UserLanguage
}

type GetGameVersionParams = {
  appId?: number
  marketIso?: string
  version?: string
}

type GetMultipleGameVersionParams = {
  apps: GetGameVersionParams[]
}

type GetRevenueAndDownloadAggregatesQueryParams = {
  appIds: number[]
  marketIsos: string[]
}

type GetTopFeaturesParams = {
  id: number
  genre: number
  marketIso: string | (string | null)[]
  store: string
  include: string
}

type CountryAggregate = {
  downloads30d: number
  downloads60d: number
  downloads90d: number
  downloads180d: number
  downloads360d: number
  downloadsLatest: number
  revenue30d: number
  revenue60d: number
  revenue90d: number
  revenue180d: number
  revenue360d: number
  revenueLatest: number
}
type RevenueAndDownloadAggregate = {
  appId: string
  countryAggregates: { [key: string]: CountryAggregate }
}
type RevenueAndDownloadAggregates = { [key: number]: RevenueAndDownloadAggregate }

type TopFeatures = {
  minEffect: number
  maxEffect: number
  avgEffect: number
}

type Language = {
  features: {
    [id: number]: {
      name: string
    }
  }
  categories: {
    [id: number]: {
      name: string
    }
  }
  tags: {
    [id: string]: {
      name: string
    }
  }
  tagGroups: {
    [id: string]: {
      name: string
    }
  }
}

export type CategoriesMap = {
  [id: string]: Category
}

type Category = {
  id: string
  active: boolean
  name: string
  ordr: number
  legacyId: number
  icon: {
    url: string
    mimeType: string
  }
  featureCount: number
  featureCounts: { [key: string]: number }
  features?: Feature[]
}

export type CategoriesFeatures = {
  [id: string]: Feature[]
}

export type ConventionalSubgenresMap = {
  [id: string]: {
    category: ConventionalCategory
    genre: ConventionalGenre
    subgenre: ConventionalSubgenre
  }
}

export type ConventionalGenresMap = {
  [id: string]: {
    category: ConventionalCategory
    genre: ConventionalGenre
  }
}

export type ConventionalCategoriesMap = {
  [id: string]: ConventionalCategory
}

type Feature = {
  id: string
  active: boolean
  choices: Choice[]
  hidden: boolean
  hideScreenshots: boolean
  legacyId: number
  name: string
  categoryId: string
  tags: string[]
  ordr: number
}

export type Choice = {
  active: boolean
  choice: string
  showInLists: boolean
  weightId: number
  ordr: number
}

type ScreenshotCounts = {
  [id: number]: number
}

type ScreenshotList = Screenshot[]

export type ScreenshotMap = {
  [country: string]: {
    [gameId: string]: {
      comments: number
      screenshots: number
    }
  }
}

type SimpleFeature = {
  choiceId?: string
  choiceLegacyId?: number
  choiceName?: string
  featureId?: string
  featureLegacyId: number
  featureName?: string
}

export type DetailedFeatureChoice = {
  active: boolean
  choice: string
  name: string
  ordr: number
  showInLists: boolean
  weightId: number
}

export type DetailedFeature = {
  active: boolean
  categoryId: string
  choices: DetailedFeatureChoice[]
  desc: string | null
  featureLegacyId: number
  featureName: string
  hidden: boolean
  hideScreenshots: boolean
  id: string
  legacyId: number
  markets: string[]
  name: string
  names: null
  ordr: number
  stores: string[] // ['565c92d39b75634154f213bc']
  tags: string[] // ['5e9d6c603ef43b00be56f344']
}

export type FileUploadResponse = {
  id: string
  url: string
  mimeType: string
}

type FileUpload = {
  body: FormData
}

export type LegacyFeatures = { [legacyId: string]: number }

type Screenshot = {
  screenshotId?: string
  commentId?: string | null
  commentPublished?: boolean
  features: SimpleFeature[] | LegacyFeatures | LegacyFeatures[]
  conceptIds: string[]
  id?: string
  gameId?: string
  gameVersion?: string
  url: string
  versionReleaseDate?: number
}

type ScreenshotComment = {
  analyst: Analyst
  screenshotComment: {
    analystId: string
    content: {
      comment: { [lang: string]: string }
    }
    gameId: string
    gameVersion?: string | null
    id: string
    published: boolean
    screenshotId: string
  }
}

export type KeywordList = Keyword[]

type Keyword = {
  id: string
  name: string
  linkedIds: number[]
  priority: number
}

type TagList = Tag[]

type Tag = {
  id: string
  name: string
  description: string | undefined
  imageText: string | undefined
  imageUrl: string | undefined
  targets: number[]
  active: boolean
  order: number
  type: 'feature' | 'game' | 'implementation' | 'chapter' | 'concept' | undefined
}

type ConceptTag = Tag &
  MongoDBObject & {
    concept: Concept
  }

type TagGroup = {
  id: string
  name: string
  tags: string[]
  order: number
  colorHex: string
  translations: {
    [key: string]: { name: string }
  }
}

type TagGroupList = TagGroup[]

export enum CollectionVisibility {
  public = 'PUBLIC',
  organization = 'ORGANIZATION',
  restricted = 'RESTRICTED',
}

type Collection = {
  allowedUsers: string[]
  createdAt: number
  createdBy: string
  id: string
  name: string
  screenshots: string[]
  visibility: CollectionVisibility
  modifiedAt?: number
}

type FtueVideoChapter = {
  chapterId: number
  dataSeek: number
  tags: string[]
  titles: { [lang: string]: string }
}

type FtueVideoThumbnail = {
  height: number
  link: string
  linkWithPlayButton: string
  width: number
}

export interface FtueVideo {
  chapters: FtueVideoChapter[]
  createdAt: number
  createdBy: string
  gameId: string
  gameVersion: string
  gameVersionReleaseDate: number
  id: string
  marketIso: string
  names: { [lang: string]: string }
  thumbnails: FtueVideoThumbnail[]
  type: string
  videoId: number
  videoName: string | null
  vimeoId: string
}

export type TrendingRequest = {
  conventionalGenreId?: string
  conventionalSubgenreId?: string
  enableCache: boolean
  endDate?: string | null // "2022-04-25"
  marketIso: string // "us"
  noChoices: boolean // false
  source: string // "revenue"
  startDate?: string | null // "2021-04-25"
  trendingDown: boolean // true
}

export type TrendingValue = {
  ts: number
  value: number
}

type TrendingFeature = {
  avgEffect: number //  0.16755443168107906
  categoryId: string // "565c92d39b75634154f213bf"
  choiceLabel: string // "Realistic"
  choiceLegacyId: number // 10
  conventionalCategory: string // "Casual"
  conventionalCategoryId: string // "5b87c6912f784f34cab23282"
  conventionalGenre: string // "AR/Location Based"
  conventionalGenreId: string // "5b87c6942f784f34cab23287"
  conventionalSubgenre: string // "AR/Location Based"
  conventionalSubgenreId: string // "5afc1e8e745d8d7640e9b1e6"
  featureId: string // "565c92d49b75634154f213df"
  featureLabel: string // "Artistic style"
  featureLegacyId: number // 5
  id: string // "6213abe456d3f4000123ef10"
  market: string // "565c92d39b75634154f213bb"
  marketIso: string // "us"
  maxEffect: number // 0.21312980900583967
  minEffect: number // 0.14476674301869874
  store: string // "565c92d39b75634154f213bc"
  visible: boolean // true
}

export type TrendingHistory = {
  [type: string]: TrendingValue[]
  [type: number]: TrendingValue[]
}

export type TrendingHistories = {
  [percentageGroup: string]: TrendingHistory
}

export type PercentageGroupValue = {
  [percentageGroup: string]: number
}

/** Note: Has more values, this is a reduced set */
export type PopularFeature = {
  choiceId: string
  choiceLabel: string // "6+"
  choiceLegacyId: number // 389
  conventionalCategoryId: string // ""
  conventionalGenreId: string
  conventionalSubgenreId: string
  featureId: string
  featureLabel: string
  featureLegacyId: number
  gamesInSet: PercentageGroupValue
  id: string
  market: string
  popularity: PercentageGroupValue
  trend: PercentageGroupValue
  visible: boolean
}

type FeatureFactsChoiceRequest = {
  choiceId: number
  conventionalCategoryId: string | null
  conventionalGenreId: string | null
  conventionalSubgenreId: string | null
  marketIso: string
  storeId: string
  userLanguage: UserLanguage
}

export type SaveLoginInfoResponse = {
  isFirst: boolean
}

export type FeatureFactsResponse = {
  count: {
    all: number
    out20Percent: number
    in20Percent: number
  }
  effects: { [key: string]: number }
  games: Game[]
  graph: {
    in20Percent: TrendingValue[]
    out20Percent: TrendingValue[]
  }
  minmaxFeature: TrendingFeature
  popularity: PopularFeature
}

export type TrendingResponse = {
  conventionalCategoryId: string | null
  conventionalGenreId: string | null
  conventionalSubgenreId: string | null
  histories: TrendingHistories
  market: string
  minmaxFeatures: { [choiceId: string]: TrendingFeature }
  popularFeatures: PopularFeature[]
  source: string
}

type GameCompetitorsResponse = {
  competitors: { [numberString: string]: Game[] }
  ranks: { [marketIso: string]: number }
}

export type DemographyGroup = {
  demographicsAge16_24?: number
  demographicsAge25_44?: number
  demographicsAge45?: number
  demographicsFemale?: number
  demographicsMale?: number
}

type FeatureDemographic = {
  choiceLegacyId: number
  demographics: DemographyGroup
  featureLegacyId: number
  id: string
  marketIso: string
}

export type CompareMultiGames = {
  games: Game[]
  features: CompareGameFeature[]
  categories: {
    [categoryId: string]: number[]
  }
}

export type CompareGameFeature = {
  featureName: string
  featureId: number
  categoryName: string
  categoryId: string
  similar: boolean
  order: number
  choices: string[]
  choiceIds: number[]
  effects: number[]
}

type MotivationProfileQueryParam = { id: number | string; isOwnGame?: boolean }
type MotivationProfileHistoryQueryParam = { appId: number }
type MotivationProfileHistoryVersionQueryParam = MotivationProfileHistoryQueryParam & { version: string }

export type Subgenre = {
  id: string
  createdAt: number
  modifiedAt: number
  createdBy: string
  modifiedBy: string
  name: string
  parentGenre: string
  parentGenreId: string
  parentCategory?: string
  rules: { [key: number]: number[] }
  description: string
  imageUrl: string
  imageText: string
  exampleAppIds: number[]
}

export type Genre = {
  id: string
  createdAt: number
  modifiedAt: number
  createdBy: string
  modifiedBy: string
  name: string
  parentGenre: string
  parentGenreId: string
  parentCategory?: string
  rules: { [key: number]: number[] }
  description: string
  imageUrl: string
  imageText: string
  exampleAppIds: number[]
  subgenres: string[]
}

type TrackedGamesListResponse = { gamesWithPermission: TrackedGame[]; gamesWithoutPermission: TrackedGame[] }

const REDUCER_PATH = 'coreApi'

export const coreApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_CORE,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ['Collection', 'Game', 'Games', 'GameAndAnalysis', 'GameAnalysis', 'AnalysisNote', 'Bookmarks', 'Concepts', 'GamesWithFilter'],
  endpoints: (builder) => ({
    getGames: builder.query<Game[], GetGamesQueryParams>({
      query: ({ ids = [] }) => `games/${ids.join(',')}`,
      transformResponse: (games: Game[], _, { userLanguage }) => {
        return games.map((game) => new Game(game, { userLanguage }))
      },
    }),
    getGame: builder.query<Game, GetGameQueryParams>({
      query: ({ id, ...rest }) => ({ url: `games/${id}`, params: { ...rest, gameOnly: true } }),
      transformResponse: (response: { game: Game }, _, { userLanguage }) => {
        return new Game(response.game, { userLanguage })
      },
      providesTags: (result, err, { id }) => [{ type: 'Game', id }],
    }),
    getGameAndAnalysis: builder.query<GameAndAnalysis, GetGameQueryParams>({
      query: ({ id, ...rest }) => ({ url: `games/${id}`, params: { ...rest, gameOnly: false } }),
      transformResponse: (response: GameAndAnalysis, _, { userLanguage }) => {
        return {
          ...response,
          game: new Game(response.game, { userLanguage }),
        }
      },
      providesTags: (result, err, { id }) => [{ type: 'GameAndAnalysis', id }],
    }),
    getMultipleGamesAndAnalysis: builder.query<GameAndAnalysis[], { queryParams: GetGameQueryParams[] }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.queryParams.map(async ({ id, ...rest }) => fetchWithBQ({ url: `/games/${id}`, params: { ...rest, gameOnly: false } }))
        )

        const data = responses.map((res) => {
          const gameAndAnalysis = res.data as GameAndAnalysis
          const game = new Game(gameAndAnalysis.game, { userLanguage: args.queryParams.find((qp) => qp.id === gameAndAnalysis.game.id)?.userLanguage })
          return { ...gameAndAnalysis, game: game } as GameAndAnalysis
        })

        const errorResponse = responses.find((r) => r.error)

        return errorResponse?.error ? { error: errorResponse.error } : { data }
      },
    }),
    getGameList: builder.query<Game[], { gameIds: (string | number)[]; include?: string; userLanguage: UserLanguage }>({
      query: ({ gameIds, include }) => ({
        url: 'games/list',
        method: 'POST',
        body: {
          gameIds,
          include: include || '',
        },
      }),
      transformResponse: (games: Game[], _, { gameIds, userLanguage }) =>
        games.map((game) => new Game(game, { userLanguage })).sort((a, b) => gameIds.indexOf(a.id) - gameIds.indexOf(b.id)),
    }),
    getGameInnovatorList: builder.query<GenreInnovatorGame[], { conventionalGenreId: string; fields: string[]; userLanguage: UserLanguage }>({
      query: ({ userLanguage, ...body }) => ({
        url: 'games/innovator/list',
        method: 'POST',
        body,
      }),
      transformResponse: (games: GenreInnovatorGame[], _, { userLanguage }) => {
        return games.map((game) => new GenreInnovatorGame(game, { userLanguage }))
      },
    }),
    getSoftLaunchGames: builder.query<Game[], { conventionalSubgenreIds: string[]; marketIso: string; includes: string[]; userLanguage: UserLanguage }>({
      query: ({ includes, marketIso, conventionalSubgenreIds }) => ({
        url: 'games/softlaunch',
        method: 'POST',
        body: {
          marketIso,
          conventionalSubgenreIds,
          include: includes.join(','),
        },
      }),
      transformResponse: (games: Game[], _, { userLanguage }) => games.map((game) => new Game(game, { userLanguage })),
    }),
    getScreenshotComment: builder.query<ScreenshotComment, string>({
      query: (commentId) => `screenshotcomments/${commentId}`,
    }),
    getScreenshotsByIds: builder.query<Screenshot[], string[]>({
      query: (screenshotIds) => ({
        url: `screenshots/list`,
        method: 'POST',
        body: screenshotIds,
      }),
    }),
    getScreenshotsByGameIds: builder.query<Screenshot[], string[]>({
      query: (gameIds) => ({
        url: `screenshots/list/games`,
        method: 'POST',
        body: gameIds,
      }),
    }),
    getScreenshotsByConceptIds: builder.query<Screenshot[], string[]>({
      query: (conceptIds) => ({
        url: `/screenshots/concepts/${conceptIds}`,
        method: 'GET',
      }),
    }),
    getSetting: builder.query<Setting, string>({
      query: (settingKey) => `settings/${settingKey}`,
    }),
    getFeatureEffects: builder.query<FeaturesEffect, void>({
      query: () => `features_effects`,
    }),
    getRevenueAndDownloadAggregates: builder.query<RevenueAndDownloadAggregates, GetRevenueAndDownloadAggregatesQueryParams>({
      query: (params) => ({
        url: '/games/revenue_downloads/aggregates',
        method: 'POST',
        body: params,
      }),
    }),
    getGamesByStage: builder.query<Game[], GetGamesByStageQueryParams>({
      query: ({ marketIso, stageId, ...queryParams }) => ({
        url: `/games/stage/${marketIso}/${stageId}`,
        params: queryParams,
      }),
      transformResponse: (response: Game[], _, { userLanguage }) => response.map((game) => new Game(game, { userLanguage })),
    }),
    sendGameAnalysisSuggestion: builder.mutation<void, GameAnalysisSuggestion>({
      query: ({ ...gameAnalysisSuggestion }) => ({
        url: `games/actions/analysis-request`,
        method: 'PUT',
        body: gameAnalysisSuggestion,
      }),
    }),
    getGameVersionInfo: builder.query<VersionInfo, GetGameVersionParams>({
      query: ({ appId, marketIso, version }) => ({
        url: `/versions/${appId}${version ? '/' + version : ''}`,
        params: { marketIso },
      }),
    }),
    getGameVersionInfoForVersion: builder.query<VersionInfo, { appId: number | undefined; version: string; marketIso: string | undefined }>({
      query: ({ appId, version, marketIso }) => ({
        url: `/versions/${appId}/${version}`,
        params: { marketIso },
      }),
    }),
    getGameVersionInfoList: builder.query<VersionInfo[], { appId: number | undefined; marketIso: string | undefined }>({
      query: ({ appId, marketIso }) => ({
        url: `/versions/${appId}/list`,
        params: { marketIso },
      }),
    }),
    getMultipleGameVersionInfoList: builder.query<VersionInfo[], GetMultipleGameVersionParams>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.apps.map(async ({ appId, marketIso, version }) => fetchWithBQ({ url: `/versions/${appId}/list`, params: { marketIso } }))
        )
        const data = responses
          .map((resp) => resp.data as VersionInfo[])
          .flat()
          .sort((a, b) => a.releaseDate - b.releaseDate)

        const errorResponse = responses.find((r) => r.error)
        return errorResponse?.error ? { error: errorResponse.error } : { data }
      },
    }),
    getFeatureFactsChoice: builder.query<FeatureFactsResponse, FeatureFactsChoiceRequest>({
      query: ({ userLanguage, ...body }) => ({
        url: `/features/facts`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: FeatureFactsResponse, _, { userLanguage }) => ({
        ...response,
        games: response.games.map((game) => new Game(game, { userLanguage })),
      }),
    }),
    getFeature: builder.query<DetailedFeature, number>({
      query: (legacyId) => ({
        url: `/features/${legacyId}`,
      }),
      transformResponse: (response: DetailedFeature) => {
        return {
          ...response,
          featureName: languageService.getTranslation('features', response.legacyId.toString()),
          choices: response.choices.map((choice) => ({
            ...choice,
            name: languageService.getTranslation('choices', choice.weightId.toString()),
          })),
        }
      },
    }),
    getActiveFeatureList: builder.query<number[], void>({
      query: () => ({
        url: `/features/list/active/legacyIds`,
      }),
    }),
    getTopFeatures: builder.query<TopFeatures, GetTopFeaturesParams>({
      query: ({ id, genre, ...queryParams }) => ({
        url: `/top-features/${id}/genre/${genre}`,
        params: queryParams,
      }),
    }),
    getFeatureByConventionalSubgenre: builder.query<TopFeatures, GetTopFeaturesParams>({
      query: ({ id, genre, ...queryParams }) => ({
        url: `/top-features/${id}/conventionalSubgenre/${genre}`,
        params: queryParams,
      }),
    }),
    searchGames: builder.mutation<Game[], SearchGamesQueryParams>({
      query: ({ userLanguage, ...searchGamesParams }) => ({
        url: `games/search`,
        method: 'POST',
        body: searchGamesParams,
        params: { enableCache: true },
      }),
      transformResponse: (games: Game[], _, { userLanguage }) => games.map((game) => new Game(game, { userLanguage })),
    }),
    searchArtist: builder.query<string[], SearchArtistQueryParams>({
      query: (queryParams) => ({
        url: `games/artist/search/${encodeURI(queryParams.term)}`,
        method: 'GET',
      }),
    }),
    getGameUpdateImpacts: builder.query<UpdateImpact[], GetGameUpdateImpactsQueryParams>({
      query: (queryParams) => ({
        url: '/impacts/list',
        params: queryParams,
      }),
      transformResponse: (updateImpacts: UpdateImpact[]) =>
        updateImpacts.map((updateImpact) => {
          return {
            ...updateImpact,
            conventionalSubgenre: languageService.getTranslation('conventionalSubgenres', updateImpact.conventionalSubgenreId) || '',
            conventionalGenre: languageService.getTranslation('conventionalGenres', updateImpact.conventionalGenreId) || '',
            conventionalCategory: languageService.getTranslation('conventionalSubgenres', updateImpact.conventionalCategoryId) || '',
          }
        }),
    }),
    getCompareMultiGames: builder.query<CompareMultiGames, GetCompareMultiGamesParams>({
      query: ({ marketIso, games }) => ({
        url: `/games/comparemulti/${marketIso.join(',')}/${games.join(',')}`,
      }),
      transformResponse: (response: CompareMultiGames, _, { userLanguage }) => ({
        ...response,
        features: response.features.map((feature) => {
          feature.featureName = languageService.getTranslation('features', feature.featureId.toString())
          return feature
        }),
        games: response.games.map((game) => new Game(game, { userLanguage })),
      }),
    }),
    getAnalysisComment: builder.query<AnalystComment, string>({
      query: (commentId) => ({
        url: `/analysiscomments/${commentId}`,
      }),
    }),
    getGamesWithFilter: builder.query<Game[], GetGamesWithFilterQueryParams>({
      query: (queryParams) => ({
        url: `/games`,
        params: queryParams,
      }),
      providesTags: ['Games', 'GamesWithFilter'],
      transformResponse: (response: Game[], _, { userLanguage }) => {
        return response.map((game) => {
          return new Game(game, { userLanguage })
        })
      },
    }),
    getUpdateImpactsForGameInMarket: builder.query<UpdateImpact[], GetGameUpdateImpactsForMarketQueryParams>({
      query: ({ appId, ...queryParams }) => ({
        url: `/impacts/market/${appId}`,
        params: queryParams,
      }),
      transformResponse: (updateImpacts: UpdateImpact[]) =>
        updateImpacts.map((updateImpact) => {
          return {
            ...updateImpact,
            conventionalSubgenre: languageService.getTranslation('conventionalSubgenres', updateImpact.conventionalSubgenreId) || '',
            conventionalGenre: languageService.getTranslation('conventionalGenres', updateImpact.conventionalGenreId) || '',
            conventionalCategory: languageService.getTranslation('conventionalSubgenres', updateImpact.conventionalCategoryId) || '',
          }
        }),
    }),
    getUpdateImpactsForMultipleGamesInMarket: builder.query<UpdateImpact[], GetMultipleGamesUpdateImpactsForMarketQueryParams>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.apps.map(async ({ appId, marketIso }) => fetchWithBQ({ url: `/impacts/market/${appId}`, params: { marketIso } }))
        )
        const data = responses
          .map((res) => res.data as UpdateImpact[])
          .flat()
          .sort((a, b) => a.versionReleaseDate - b.versionReleaseDate)
        const errorResponse = responses.find((r) => r.error)
        return errorResponse?.error ? { error: errorResponse.error } : { data }
      },
    }),
    getUpdateImpactForGameVersion: builder.query<UpdateImpact, { appId: number; version: string; marketIso: string }>({
      query: ({ appId, version, marketIso }) => ({
        url: `/impacts/market/${appId}/${version}`,
        params: {
          marketIso,
        },
      }),
    }),
    getEstimatesList: builder.query<EstimatesList, GetEstimatesListQueryParams>({
      query: (queryParams) => ({
        url: '/estimates/list',
        method: 'POST',
        body: queryParams,
      }),
    }),
    getMarkets: builder.query<Market[], { userLanguage: UserLanguage }>({
      query: () => 'markets',
      transformResponse: (response: Market[], _, userLanguage) => {
        const markets = response
          .map((market) => {
            return { ...market, name: languageService.getTranslation('markets', market.iso) }
          })
          .sort((a, b) => (a.name < b.name ? -1 : 1))
        return markets
      },
    }),
    getStores: builder.query<Store[], void>({
      query: () => 'stores',
    }),
    getGenreTaxonomy: builder.query<ConventionalCategory[], { userLanguage: UserLanguage }>({
      query: () => `conventional/map`,
      transformResponse: (
        response: { [key: string]: { [key: string]: Array<string> } },
        _,
        { userLanguage /* Enables Localization when user language changes */ }
      ) => {
        const priorityMap: { [categoryId: string]: number } = {
          '5b87c6912f784f34cab23282': 1, // Casual
          '5b87c6902f784f34cab23280': 2, // Mid-core
          '5b87c6992f784f34cab2328b': 3, // Sports & Driving
          '5b87c69b2f784f34cab2328e': 4, // Casino
        }

        return Object.keys(response)
          .map((categoryId) => ({
            name: languageService.getTranslation('conventionalCategories', categoryId),
            id: categoryId,
            genres: Object.keys(response[categoryId])
              .map((genreId) => ({
                name: languageService.getTranslation('conventionalGenres', genreId),
                id: genreId,
                categoryId: categoryId,
                subgenres: response[categoryId][genreId]
                  .map((subgenreId) => ({
                    name: languageService.getTranslation('conventionalSubgenres', subgenreId),
                    id: subgenreId,
                    genreId: genreId,
                    categoryId: categoryId,
                  }))
                  .sort(compareNames),
              }))
              .sort(compareNames),
            priority: priorityMap[categoryId] || 100,
          }))
          .sort(comparePriority)
      },
    }),
    getConventionalGenreList: builder.query<Genre[], { userLanguage: UserLanguage }>({
      query: () => `/conventional/genre/list`,
      transformResponse: (response: Genre[], _, userLanguage) => {
        return response.map((genre) => {
          return {
            ...genre,
            name: languageService.getTranslation('conventionalGenres', genre.id),
            description:
              languageService.getTranslation('conventionalGenres', genre.id, 'description', true) !== ''
                ? languageService.getTranslation('conventionalGenres', genre.id, 'description')
                : genre.description,
          }
        })
      },
    }),
    getConventionalSubgenreList: builder.query<Subgenre[], { userLanguage: UserLanguage }>({
      query: () => `/conventional/subgenre/list`,
      transformResponse: (response: Subgenre[], _, userLanguage) => {
        return response.map((subgenre) => {
          return {
            ...subgenre,
            name: languageService.getTranslation('conventionalSubgenres', subgenre.id),
            parentGenre: languageService.getTranslation('conventionalGenres', subgenre.parentGenreId),
            description:
              languageService.getTranslation('conventionalSubgenres', subgenre.id, 'description', true) !== ''
                ? languageService.getTranslation('conventionalSubgenres', subgenre.id, 'description')
                : subgenre.description,
          }
        })
      },
    }),
    getLanguageMap: builder.query<LanguageMap, string>({
      query: (language) => `language/${language}`,
    }),
    getLanguage: builder.query<Language, { language: string }>({
      query: ({ language }) => ({
        url: `/language/${language}`,
      }),
    }),
    getCategoriesMap: builder.query<CategoriesMap, { marketIso: string }>({
      query: ({ marketIso }) => ({
        url: `/categories/map/${marketIso}`,
      }),
      transformResponse: (categoriesMap: CategoriesMap) => {
        return Object.entries(categoriesMap).reduce((acc, [categoryId, category]) => {
          acc[categoryId] = {
            ...category,
            name: languageService.getTranslation('categories', category.legacyId + ''),
          }

          return acc
        }, {} as CategoriesMap)
      },
    }),
    getCategoriesFeatures: builder.query<CategoriesFeatures, { categories: string[]; marketIso: string }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(args.categories.map(async (category) => fetchWithBQ({ url: `/categories/${category}/features/${args.marketIso}` })))
        const errorResponse = responses.find((r) => r.error)
        return errorResponse?.error
          ? { error: errorResponse.error }
          : { data: Object.fromEntries(args.categories.map((c, i) => [c, responses[i].data as Feature[]])) }
      },
    }),
    getScreenshotList: builder.query<ScreenshotList, string[]>({
      query: (screenshotIds) => ({
        url: 'screenshots/list',
        method: 'POST',
        body: screenshotIds,
      }),
      transformResponse: (response: ScreenshotList, meta, screenshotsIds) => {
        if (screenshotsIds.length !== response.length) {
          throw new Error(`Expected ${screenshotsIds.length} screenshots, but got ${response.length}`)
        }

        return Object.values(response).map((screenshot) => ({
          ...screenshot,
          features: Object.entries(screenshot.features).map(
            ([featureId, choiceId]) =>
              ({
                featureLegacyId: parseInt(featureId, 10),
                featureName: languageService.getTranslation('features', featureId),
                choiceLegacyId: choiceId as number,
                choiceName: languageService.getTranslation('choices', choiceId),
              } as SimpleFeature)
          ),
        }))
      },
    }),
    getScreenshotsByFeatures: builder.query<ScreenshotList, { choices: number[] }>({
      query: ({ choices }) => ({
        url: `/screenshots/features?choices=${choices.join(',')}`,
      }),
      transformResponse: (response: ScreenshotList) => {
        // Sort the screenshots by their ID in ascending order
        response.sort((a, b) => (a.id as string).localeCompare(b.id as string))
        return response
      },
    }),
    getScreenshotCounts: builder.query<ScreenshotCounts, { featureLegacyId?: number }>({
      query: ({ featureLegacyId }) => ({
        url: `/screenshots/count${featureLegacyId ? `?featureLegacyId=${featureLegacyId}` : ''}`,
      }),
    }),
    getScreenshotCountsByGames: builder.query<{ [gameId: string]: ScreenshotCounts }, { gameIds: string[] }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(args.gameIds.map(async (gameId) => fetchWithBQ({ url: `/screenshots/count/${gameId}` })))
        const errorResponse = responses.find((r) => r.error)
        return errorResponse?.error
          ? { error: errorResponse.error }
          : {
              data: args.gameIds.reduce(
                (acc, gameId, i) => ({
                  ...acc,
                  [gameId]: responses[i].data as ScreenshotCounts,
                }),
                {}
              ),
            }
      },
    }),
    getScreenshotCountsByGameAndCountry: builder.query<ScreenshotMap, void>({
      query: () => ({
        url: `/screenshots/map`,
      }),
    }),
    getImageObjectUrl: builder.query<string, { url: string }>({
      query: ({ url }) => {
        const originalUrl = new URL(url)
        return {
          url: originalUrl.pathname.replace('/v2/', '/'),
          headers: {
            Accept: '*/*',
          },
          responseHandler: async (response) => URL.createObjectURL(await response.blob()),
        }
      },
    }),
    getKeywordList: builder.query<KeywordList, { type: string; userLanguage?: UserLanguage }>({
      query: ({ type }) => ({
        url: `/keywords/list/${type}`,
      }),
      transformResponse: (response: Keyword[], _, userLanguage) => {
        return response.map((keyword) => {
          return {
            ...keyword,
            name: languageService.getTranslation('keywords', keyword.id, 'name'),
          }
        })
      },
    }),
    getTagList: builder.query<TagList, { type: string; userLanguage: UserLanguage }>({
      query: ({ type }) => ({
        url: `/tags/list?type=${type}`,
      }),
      transformResponse: (response: Tag[], _, { userLanguage }) => {
        return response.map((tag) => {
          return {
            ...tag,
            name: languageService.getTranslation('tags', tag.id, 'name'),
          }
        })
      },
    }),
    getTagGroupsList: builder.query<TagGroupList, { type: string; userLanguage: UserLanguage }>({
      query: ({ type }) => ({
        url: `/tag-groups/list`,
        params: {
          type,
        },
      }),
      transformResponse: (response: TagGroup[], _, { userLanguage }) => {
        return response
          .map((tagGroup) => {
            return {
              ...tagGroup,
              name: languageService.getTranslation('tagGroups', tagGroup.id, 'name'),
            }
          })
          .sort((a, b) => a.order - b.order)
      },
    }),
    getVisibleCollections: builder.query<Collection[], void>({
      query: () => ({
        url: `/pinnedscreenshots/listvisible`,
      }),
      providesTags: ['Collection'],
    }),
    updateCollection: builder.mutation<void, Pick<Collection, 'id' | 'allowedUsers' | 'name' | 'visibility' | 'screenshots'>>({
      query: (collection) => ({
        url: `/pinnedscreenshots/${collection.id}`,
        method: 'PUT',
        body: collection,
      }),
      invalidatesTags: ['Collection'],
    }),
    createCollection: builder.mutation<void, Pick<Collection, 'allowedUsers' | 'name' | 'visibility' | 'screenshots'>>({
      query: (collection) => ({
        url: `/pinnedscreenshots`,
        method: 'POST',
        body: collection,
      }),
      invalidatesTags: ['Collection'],
    }),
    deleteCollection: builder.mutation<void, string>({
      query: (collectionId) => ({
        url: `/pinnedscreenshots/${collectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Collection'],
    }),
    getGameReview: builder.query<GameReview, string>({
      query: (reviewId) => `/gamereviews/${reviewId}`,
    }),
    startIntercomConversation: builder.query<{}, IntercomConversationQueryParams>({
      query: (conversation) => ({
        url: `/conversations/start`,
        method: 'POST',
        body: conversation,
      }),
    }),
    getLatestFtueVideos: builder.query<FtueVideo[], void>({
      query: () => `/ftue-videos/list/latest/?type=ftue`,
    }),
    getLatestFtueVideosByGameIds: builder.query<FtueVideo[], { gameIds: string[] }>({
      query: ({ gameIds }) => ({
        url: `/ftue-videos/list`,
        method: 'POST',
        body: gameIds,
      }),
    }),
    getFtueVideosByGame: builder.query<FtueVideo[], string>({
      query: (gameId) => `/ftue-videos/game/${gameId}`,
      transformResponse: (response: FtueVideo[]) => {
        // sort videos from latest to oldest
        return response.sort((a, b) => {
          return b.createdAt - a.createdAt
        })
      },
    }),
    getTutorialVideos: builder.query<FtueVideo[], void>({
      query: () => `/ftue-videos/?type=onboarding`,
    }),
    postUploadFile: builder.mutation<FileUploadResponse, FileUpload>({
      query: ({ body }) => ({
        url: `/files/upload/private`,
        method: 'POST',
        body,
      }),
    }),
    postCreateNewGame: builder.mutation<Game, NewGameMetaData>({
      query: (NewGameMetaData) => ({
        url: `/games`,
        method: 'POST',
        body: NewGameMetaData,
      }),
      invalidatesTags: ['Games'],
    }),
    updateGame: builder.mutation<Game, { game: Game; gameId: string }>({
      query: ({ game, gameId }) => ({
        url: `/games/${gameId}`,
        method: 'PUT',
        body: game,
      }),
      invalidatesTags: ['Games', 'Game'],
    }),
    deleteGame: builder.mutation<Game, string>({
      query: (gameId) => ({
        url: `/games/${gameId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Games'],
    }),
    cloneGame: builder.mutation<Game, { gameId: string; userLanguage: UserLanguage }>({
      query: ({ gameId }) => ({
        url: `/games/clone/${gameId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Games'],
      transformResponse: (response: Game, _, { userLanguage }) => new Game(response, { userLanguage }),
    }),
    getGameCompetitors: builder.query<Game[], { gameId: string; marketIso: string; userLanguage: UserLanguage }>({
      query: ({ gameId, marketIso }) => ({
        url: `/games/${gameId}/competitors/${marketIso}`,
      }),
      transformResponse: (response: GameCompetitorsResponse, _, { userLanguage }) => {
        let games: Game[] = []
        let competitorOrder = 1
        Object.values(response.competitors).forEach((gameArray) => {
          gameArray.forEach((gameData) => {
            const game = new Game(gameData, { userLanguage })
            game.competitorOrder = competitorOrder
            games.push(game)
            competitorOrder++
          })
        })
        return games
      },
    }),
    getMarketFeatures: builder.query<Feature[], { marketIso: string; featureLegacyIds: number[] }>({
      query: ({ marketIso, featureLegacyIds }) => ({
        url: `/features/market/${marketIso}?featureLegacyIds=${featureLegacyIds.join(',')}`,
      }),
    }),
    getAllMarketFeatures: builder.query<Feature[], { marketIso: string }>({
      query: ({ marketIso }) => ({
        url: `/features/market/${marketIso}`,
      }),
    }),
    getExplorerFeaturesForConventionalSubgenres: builder.query<
      TopFeature[],
      { marketIso: string; selectedGameId?: string; conventionalSubgenreIds?: string[] }
    >({
      query: ({ marketIso, selectedGameId, conventionalSubgenreIds }) => ({
        url: '/top-features/explorer/market',
        method: 'POST',
        body: {
          marketIso,
          conventionalSubgenreIds: conventionalSubgenreIds?.join(','),
          selectedGameId,
        },
      }),
      transformResponse: (topFeatures: TopFeature[]) => topFeatures.map((topFeature) => featureService.localizeFeature(topFeature)) as TopFeature[],
    }),
    getAnalysisFeatureChoices: builder.query<FeatureChoice[], { analysisId: string; marketIso: string; storeId: string }>({
      query: ({ analysisId, marketIso, storeId }) => ({
        url: `/top-features/analysis/${analysisId}`,
        params: {
          storeId,
          marketIso,
        },
      }),
      transformResponse: (featureChoices: FeatureChoice[]) =>
        featureChoices.map((featureChoice) => featureService.localizeFeature(featureChoice)) as FeatureChoice[],
    }),
    getGameLatestAnalysis: builder.query<Analysis, { id: string; marketIso: string; onlyCompleted?: boolean }>({
      query: ({ id, marketIso, onlyCompleted = false }) => ({
        url: `/games/${id}/analyses/latest`,
        params: {
          onlyCompleted,
          marketIso,
        },
      }),
      providesTags: (result, err, { id }) => [{ type: 'GameAnalysis', id }],
    }),
    getTrending: builder.query<TrendingResponse[], TrendingRequest>({
      query: (body) => ({
        url: '/top-features/trending',
        method: 'POST',
        body,
      }),
    }),
    getGameLatestAndPrevAnalysis: builder.query<{ latest?: Analysis; prev?: Analysis }, { id: string; marketIso: string; onlyCompleted?: boolean }>({
      query: ({ id, marketIso, onlyCompleted = false }) => ({
        url: `/games/${id}/analyses/latestandprev`,
        params: {
          onlyCompleted,
          marketIso,
        },
      }),
      providesTags: (result, err, { id }) => [{ type: 'GameAnalysis', id }],
    }),
    updateFeatureAnswer: builder.mutation<Analysis, { id: string; gameId: string; add: boolean; payload: UpdateAnalysisAnswerPayload }>({
      query: ({ id, add, payload }) => ({
        url: `/analyses/${id}`,
        method: 'PUT',
        params: {
          add,
        },
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: payload,
      }),
      invalidatesTags: (result, err, { gameId }) => [{ type: 'GameAnalysis', id: gameId, state: 'refresh-unfinalized' }],
    }),
    getRefreshAnalysis: builder.query<Analysis, { id: string; gameId: string; unfinalizedOnly?: boolean }>({
      query: ({ id, unfinalizedOnly = true }) => ({
        url: `/analyses/${id}/refresh`,
        params: {
          unfinalizedOnly,
        },
      }),
      providesTags: (result, err, { gameId, unfinalizedOnly }) => [
        { type: 'GameAnalysis', id: gameId, state: unfinalizedOnly ? 'refresh-unfinalized' : 'refresh-finalized' },
      ],
    }),
    finalizeAnalysis: builder.mutation<Analysis, { id: string; gameId: string; type: string; marketIso: string }>({
      query: ({ id, type, marketIso }) => ({
        url: `/analyses/${id}/finalize`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        params: {
          type,
          marketIso,
        },
      }),
      invalidatesTags: (result, err, { gameId }) => [
        { type: 'GameAnalysis', id: gameId, state: 'refresh-finalized' },
        { type: 'GameAnalysis', id: gameId, state: 'refresh-game' },
        { type: 'GameAndAnalysis', id: gameId },
        { type: 'Game', id: gameId },
        { type: 'GamesWithFilter' },
      ],
    }),
    getFeatureDemographicsAvgs: builder.query<{ [marketIso: string]: FeatureDemographic[] }, string[]>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(args.map(async (marketIso) => fetchWithBQ({ url: `/features/demographics/averages/all?marketIso=${marketIso}` })))
        const errorResponse = responses.find((r) => r.error)
        return errorResponse?.error
          ? { error: errorResponse.error }
          : {
              data: args.reduce(
                (acc, marketIso, i) => ({
                  ...acc,
                  [marketIso]: responses[i].data as FeatureDemographic[],
                }),
                {}
              ),
            }
      },
    }),
    getExplorerDataWithGames: builder.query<MarketExplorerSegmentData, GetExplorerDataWithGamesQueryParams>({
      query: ({ marketIso, conventionalSubgenreIds, dataFilters, ownGames, gameIds }) => ({
        url: '/games/explorer/market',
        method: 'POST',
        params: { ownGames, enableCache: true },
        body: {
          marketIso,
          conventionalSubgenreIds:
            conventionalSubgenreIds &&
            Object.entries(conventionalSubgenreIds)
              .filter(([subgenreId, active]) => active)
              .map(([subgenreId, active]) => subgenreId)
              .join(','),
          dataFilters,
          gameIds: gameIds?.join(','),
        },
      }),
      transformResponse: (response: MarketExplorerSegmentData, _, { userLanguage }) => transformMarketExplorerSegmentData(response, userLanguage),
    }),
    getExplorerDataWithFeatures: builder.query<MarketExplorerSegmentData, GetExplorerDataWithFeaturesQueryParams>({
      query: ({ marketIso, conventionalSubgenreIds, dataFilters, ownGames, choiceLegacyIds }) => ({
        url: '/games/explorer/choices',
        method: 'POST',
        params: { ownGames, enableCache: false },
        body: {
          marketIso,
          conventionalSubgenreIds:
            conventionalSubgenreIds &&
            Object.entries(conventionalSubgenreIds)
              .filter(([subgenreId, active]) => active)
              .map(([subgenreId, active]) => subgenreId)
              .join(','),
          dataFilters,
          choiceLegacyIds: choiceLegacyIds?.join(','),
        },
      }),
      transformResponse: (response: MarketExplorerSegmentData, _, { userLanguage }) => transformMarketExplorerSegmentData(response, userLanguage),
    }),
    getExplorerDataByChoices: builder.query<ExplorerDataByChoice, GetExplorerDataByChoicesQueryParams>({
      query: ({ conventionalSubgenreIds, choiceLegacyIds, ...rest }) => ({
        url: '/games/explorer/choices',
        method: 'POST',
        body: {
          ...rest,
          choiceLegacyIds: choiceLegacyIds?.join(','),
          conventionalSubgenreIds: conventionalSubgenreIds.join(','),
        },
      }),
      transformResponse: (response: ExplorerDataByChoice, _, { userLanguage }) => transformExplorerDataByChoice(response, userLanguage),
    }),

    getExplorerGamesWithChoice: builder.query<string[], GetGamesWithChoiceQueryParams>({
      query: ({ marketIso, gameIds, choiceLegacyId }) => ({
        url: `/games/explorer/gamemap`,
        method: 'POST',
        body: {
          marketIso,
          gameIds,
          choiceLegacyId,
        },
      }),
    }),
    getLatestMotivationProfile: builder.query<ProfileMotivation, MotivationProfileQueryParam>({
      query: ({ id, isOwnGame }) => (isOwnGame ? `/motivations/profiles/private/${id}` : `/motivations/profiles/public/latest/${id}`),
    }),
    getMotivationProfileHistory: builder.query<ProfileMotivation[], MotivationProfileHistoryQueryParam>({
      query: ({ appId }) => `/motivations/profiles/public/history/${appId}`,
    }),
    getMotivationProfileVersions: builder.query<string[], MotivationProfileHistoryQueryParam>({
      query: ({ appId }) => `/motivations/profiles/public/history/${appId}/versions`,
      transformResponse: (response: string[]) => response.filter((value) => value),
    }),
    getMotivationProfileVersion: builder.query<ProfileMotivation, MotivationProfileHistoryVersionQueryParam>({
      query: ({ appId, version }) => `/motivations/profiles/public/history/${appId}/version/${version}`,
    }),
    getMultipleLatestMotivationProfile: builder.query<ProfileMotivation[], { queryParams: MotivationProfileQueryParam[] }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.queryParams.map(async ({ id, isOwnGame }) => fetchWithBQ({ url: `/motivations/profiles/${isOwnGame ? 'private' : 'public/latest'}/${id}` }))
        )
        const data = responses.map((res) => res.data as ProfileMotivation)
        const errorResponse = responses.find((r) => r.error)

        return errorResponse?.error ? { error: errorResponse.error } : { data }
      },
    }),

    getPublisherGameAppIds: builder.query<number[], { appId: number }>({
      query: ({ appId }) => `artist/byapp/${appId}`,
    }),
    getAnalysisNotes: builder.query<AnalysisNotes, { gameId: string }>({
      query: ({ gameId }) => `analyses/notes/${gameId}`,
      providesTags: (result, err, { gameId }) => [{ type: 'AnalysisNote', gameId }],
    }),
    updateAnalysisNotes: builder.mutation<void, AnalysisNote>({
      query: (body) => ({
        url: '/analyses/notes',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, err, { gameId }) => [{ type: 'AnalysisNote', gameId }],
    }),
    deleteAnalysisNote: builder.mutation<void, { gameId: string; noteId: string }>({
      query: ({ noteId }) => ({
        url: `/analyses/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, err, { gameId }) => [{ type: 'AnalysisNote', gameId }],
    }),
    getTrackedGame: builder.query<TrackedGame, { gameId: string; userLanguage: UserLanguage }>({
      query: ({ gameId }) => ({
        url: `/games/tracked/${gameId}`,
        method: 'GET',
      }),
      transformResponse: (response: TrackedGame, _, { userLanguage }) => {
        const game = new Game(response.game, { userLanguage })
        return { ...response, game }
      },
    }),
    getTrackedGames: builder.query<TrackedGame[], { eventTypes?: string[]; userLanguage: UserLanguage }>({
      query: ({ eventTypes }) => ({
        url: '/games/tracked/list',
        method: 'POST',
        body: {
          eventTypes,
        },
      }),
      transformResponse: (response: TrackedGamesListResponse, _, { userLanguage }) => {
        const trackedGames: TrackedGame[] = []
        Object.keys(response as TrackedGamesListResponse).forEach((key) => {
          if (key === 'gamesWithPermission' || 'gamesWithoutPermission') {
            const permission = key === 'gamesWithPermission' ? true : false
            ;(response as TrackedGamesListResponse)[key as 'gamesWithPermission' | 'gamesWithoutPermission'].forEach((trackedGame) => {
              const game = new Game(trackedGame.game, { userLanguage })
              trackedGames.push({
                ...{ ...trackedGame, game },
                permission,
                game,
              })
            })
          }
        })
        return trackedGames
      },
    }),
    getEventsForTrackedGames: builder.query<TrackingEventsByGame, { gameIds: string[]; startTimestamp: number; endTimestamp: number; marketIso: string }>({
      query: ({ gameIds, startTimestamp, endTimestamp, marketIso }) => ({
        url: '/games/tracked/list/games',
        method: 'POST',
        body: {
          gameIds,
          startTimestamp,
          endTimestamp,
          marketIso,
        },
      }),
      transformResponse: (response: TrackingEventsByGame) => {
        const transformedEvents = Object.entries(response.events || {}).reduce((acc, [gameId, events]) => {
          const eventsWithExtraData = events.map((event) => {
            return {
              ...event,
              screenshotUrl:
                response.extraEventData && response.extraEventData[event.eventId] ? response.extraEventData[event.eventId].screenshotUrl : undefined,
              comment: response.extraEventData && response.extraEventData[event.eventId] ? response.extraEventData[event.eventId].comment : undefined,
              filteredMotivations:
                response.extraEventData && response.extraEventData[event.eventId]
                  ? motivationService.buildAndFilterMotivationsForLiveEvent(
                      response.extraEventData[event.eventId].motivations,
                      response.extraEventData[event.eventId].motivationSegments
                    )
                  : undefined,
            }
          })

          acc[gameId] = eventsWithExtraData

          return acc
        }, {} as TrackingEventByGame)
        response.events = transformedEvents

        const transformedComments = Object.entries(response.comments || {}).reduce((acc, [gameId, comments]) => {
          const commentsWithAnalystData = comments.map((comment) => {
            return {
              ...comment,
              comment: {
                ...comment.comment,
                analyst: Object.values(response.analysts || {}).find((analyst) => analyst.id === comment.comment.analystId),
              },
            }
          })

          acc[gameId] = commentsWithAnalystData

          return acc
        }, {} as { [gameId: string]: LiveEventReview[] })
        response.comments = transformedComments

        return response
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const result = dispatch(
          coreApi.util.updateQueryData('getEventsForTrackedGames', args, (draft) => {
            args.gameIds.reduce(
              (acc, gameId) => {
                acc.events[gameId] = draft?.events?.[gameId] || {}
                if (acc.performanceChanges) {
                  acc.performanceChanges[gameId] = draft?.performanceChanges?.[gameId] || []
                }

                return acc
              },
              { events: {}, performanceChanges: {} } as TrackingEventsByGame
            )
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    getTrackedEvent: builder.query<LiveTrackingEvent, string>({
      query: (eventId) => `/games/tracked/events/${eventId}`,
      transformResponse: (response: LiveTrackingEvent) => {
        const transformedEvent = { ...response }

        transformedEvent.filteredMotivations = motivationService.buildAndFilterMotivationsForLiveEvent(
          response.motivations?.motivations,
          response.motivations?.motivationSegments
        )

        response = transformedEvent

        return response
      },
    }),
    tutorialVideo: builder.query<void, { tab: string }>({
      query: ({ tab }) => ({
        url: 'users/urlhistory',
        method: 'POST',
        body: { params: { tab }, path: 'main.data-glossary' },
      }),
    }),
    getConventionalCategories: builder.query<ConventionalCategory[], void>({
      query: () => ({
        url: '/conventional/map',
      }),
      transformResponse: (response: { data: ConventionalCategory }) => {
        return Object.entries(response).map(([categoryId, category]) => {
          return {
            id: categoryId,
            name: languageService.getTranslation('conventionalCategories', categoryId),
            priority: 0,
            genres: Object.entries(category).map(([genreId, genre]) => {
              return {
                id: genreId,
                categoryId: categoryId,
                name: languageService.getTranslation('conventionalGenres', genreId),
                subgenres: genre.map((subgenreId: string) => {
                  return {
                    id: subgenreId,
                    name: languageService.getTranslation('conventionalSubgenres', subgenreId),
                    categoryId,
                    genreId,
                  } as ConventionalSubgenre
                }) as ConventionalSubgenre[],
              } as ConventionalGenre
            }) as ConventionalGenre[],
          } as ConventionalCategory
        }) as ConventionalCategory[]
      },
    }),
    getBookmarks: builder.query<BookmarksData, GetBookmarksQueryParams>({
      query: (queryParams: GetBookmarksQueryParams) => ({
        url: `/bookmark/list?isPublic=${queryParams.isPublic}`,
        method: 'GET',
      }),
      transformResponse: (response: Bookmark[], _, args) => {
        const email = args.userEmail

        // Sortb bookmarks by creation date from newest to oldest
        response = response.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))

        // Separate bookmarks by owner
        const bookmarksData = {
          own: response.filter((bookmark) => bookmark.email === email),
          shared: response.filter((bookmark) => bookmark.isPublic && bookmark.email !== email),
        } as BookmarksData

        return bookmarksData
      },
      providesTags: ['Bookmarks'],
    }),
    updateBookmark: builder.mutation<void, Pick<Bookmark, 'id' | 'name' | 'description' | 'imageUrl' | 'urlPath' | 'isPublic'>>({
      query: (bookmark) => ({
        url: `/bookmark/save`,
        method: 'PUT',
        body: bookmark,
      }),
      invalidatesTags: ['Bookmarks'],
    }),
    deleteBookmark: builder.mutation<void, string>({
      query: (bookmarkId) => ({
        url: `/bookmark/${bookmarkId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Bookmarks'],
    }),
    createBookmark: builder.mutation<void, { bookmark: Bookmark; imageDataBase64: string }>({
      query: ({ bookmark, imageDataBase64 }) => ({
        url: `/bookmark/save`,
        method: 'POST',
        body: {
          bookmark,
          screenshot: imageDataBase64,
        },
      }),
      invalidatesTags: ['Bookmarks'],
    }),
    getOwnGamesCount: builder.query<number, void>({
      query: () => ({ url: '/games/count/own', method: 'GET' }),
      providesTags: ['Games'],
    }),
    getAnalystsMap: builder.query<{ [analystId: string]: Analyst }, void>({
      query: () => `analysts/list`,
      transformResponse: (response: Analyst[]) => {
        return response.reduce((acc, analyst) => {
          acc[analyst.id] = analyst
          return acc
        }, {} as { [analystId: string]: Analyst })
      },
    }),
    getConcepts: builder.query<Concept[], void>({
      query: () => `/concepts`,
      providesTags: ['Concepts'],
    }),
    getConcept: builder.query<Concept, string>({
      query: (conceptId) => `/concepts/${conceptId}`,
      providesTags: ['Concepts'],
    }),
    getActiveUsers: builder.query<ActiveUsersData, { appId: number }>({
      query: ({ appId }) => ({
        url: `active_users/app/${appId}`,
        method: 'GET',
      }),
    }),
    postPublicFiles: builder.mutation<UploadedFile[], File[]>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.map(async (file) => {
            const formData = new FormData()
            formData.set('file', file)
            formData.set('type', 'screenshot')
            formData.set('route', 'public')
            return fetchWithBQ({ url: `files/upload/public`, method: 'POST', body: formData })
          })
        )
        const data = responses.map((res) => res.data as UploadedFile)
        const errorResponse = responses.find((r) => r.error)

        return errorResponse?.error ? { data: undefined, error: errorResponse.error } : { data, error: undefined }
      },
    }),
  }),
})

const transformMarketExplorerSegmentData = (response: MarketExplorerSegmentData, userLanguage: UserLanguage) => ({
  ...response,
  games: response.games.map((game) => new Game(game, { userLanguage })),
  features: response.features.map((feature) => featureService.localizeFeature(feature)) as MarketExplorerSegmentDataFeature[],
})

const transformExplorerDataByChoice = (response: ExplorerDataByChoice, userLanguage: UserLanguage) => ({
  ...response,
  games: response.games.map((game) => new Game(game, { userLanguage })),
  features: response.features && (response.features.map((feature) => featureService.localizeFeature(feature)) as FeatureChoice[]),
})

export const compareNames = (a: { name: string }, b: { name: string }) => (a.name < b.name ? -1 : 1)
const comparePriority = (a: { priority: number }, b: { priority: number }) => a.priority - b.priority

type CommonExplorerDataWithGamesQueryParams = {
  marketIso: string
  conventionalSubgenreIds?: SubgenreMap
  dataFilters?: DataFilters
  ownGames?: boolean
  userLanguage: UserLanguage
}

type GetExplorerDataWithGamesQueryParams = CommonExplorerDataWithGamesQueryParams & {
  gameIds?: string[]
}

type GetExplorerDataWithFeaturesQueryParams = CommonExplorerDataWithGamesQueryParams & {
  choiceLegacyIds?: number[]
}

type GetGamesWithFilterQueryParams = {
  filter: GameFilter[] // "own" returns public and organizations private games, "private" returns only private games, "public" returns only public archived games (internal == true && archive == true), "internal" returns all public games (internal = true) it requires the "internal" user role")
  include: string[]
  marketIso: string
  userLanguage: UserLanguage
}

type GetGameUpdateImpactsQueryParams = {
  marketIso: string
  subgenre?: string
  limit?: number
  ts?: number
  minRevenue?: number
  enableCache?: boolean
}

type GetCompareMultiGamesParams = {
  games: string[]
  marketIso: string[]
  userLanguage: UserLanguage
}

type GetGamesByStageQueryParams = {
  marketIso: string
  stageId: number
  limit?: number
  include: string[]
  includeUnanalyzed: boolean
  userLanguage: UserLanguage
}

type GetGameUpdateImpactsForMarketQueryParams = {
  appId?: string | number
  marketIso: string
}

type GetMultipleGamesUpdateImpactsForMarketQueryParams = {
  apps: GetGameVersionParams[]
}

type GetEstimatesListQueryParams = {
  appIds: number[]
  markets: string[]
  platforms: EstimatePlatformType[]
}

type GetGamesWithChoiceQueryParams = {
  marketIso: string
  gameIds: string[]
  choiceLegacyId: number
}

export type GetBookmarksQueryParams = {
  userEmail: string
  isPublic: boolean
}

type GetExplorerDataByChoicesQueryParams = {
  marketIso: string
  choiceLegacyIds: []
  excludeMotivationData?: boolean
  conventionalSubgenreIds: string[]
  excludeFeatures?: boolean
  userLanguage: UserLanguage
}

type ExplorerDataByChoice = { games: Game[]; features?: FeatureChoice[] }

export type IntercomConversationQueryParams = {
  userMessage: string
  userEmail: string
  assigneeIntercomId: string
  assigneeReplyMessage: string
}

export const unauthenticatedCoreApi = createApi({
  reducerPath: 'unauthenticatedCoreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_CORE,
  }),
  endpoints: (builder) => ({
    unsubscribeDailyDigest: builder.mutation<void, string>({
      query: (token) => ({
        url: `/public/users/unsubscribe/${token}`,
        method: 'PUT',
      }),
    }),
  }),
})

// Export hooks to use in function components
export const {
  useGetGamesQuery,
  useGetGameQuery,
  useGetGameAndAnalysisQuery,
  useGetGameListQuery,
  useGetSettingQuery,
  useGetFeatureQuery,
  useGetFeatureFactsChoiceQuery,
  useGetRevenueAndDownloadAggregatesQuery,
  useGetGamesByStageQuery,
  useSendGameAnalysisSuggestionMutation,
  useGetGameVersionInfoQuery,
  useGetGameVersionInfoListQuery,
  useGetTopFeaturesQuery,
  useGetFeatureByConventionalSubgenreQuery,
  useSearchGamesMutation,
  useSearchArtistQuery,
  useGetGameUpdateImpactsQuery,
  useGetAnalysisCommentQuery,
  useGetGamesWithFilterQuery,
  useGetUpdateImpactsForGameInMarketQuery,
  useGetEstimatesListQuery,
  useGetMarketsQuery,
  useGetStoresQuery,
  useGetGenreTaxonomyQuery,
  useGetConventionalGenreListQuery,
  useGetConventionalSubgenreListQuery,
  useGetLanguageQuery,
  useGetCategoriesMapQuery,
  useGetCategoriesFeaturesQuery,
  useGetScreenshotCommentQuery,
  useGetScreenshotsByIdsQuery,
  useGetScreenshotsByFeaturesQuery,
  useGetScreenshotsByGameIdsQuery,
  useGetScreenshotsByConceptIdsQuery,
  useGetScreenshotCountsQuery,
  useGetScreenshotCountsByGameAndCountryQuery,
  useGetImageObjectUrlQuery,
  useGetKeywordListQuery,
  useGetTagListQuery,
  useGetTagGroupsListQuery,
  useGetVisibleCollectionsQuery,
  useCreateCollectionMutation,
  useUpdateCollectionMutation,
  useDeleteCollectionMutation,
  useGetGameReviewQuery,
  useGetScreenshotListQuery,
  useGetGameVersionInfoForVersionQuery,
  useGetLatestFtueVideosQuery,
  useGetLatestFtueVideosByGameIdsQuery,
  useGetFtueVideosByGameQuery,
  usePostUploadFileMutation,
  usePostCreateNewGameMutation,
  useGetGameCompetitorsQuery,
  useGetExplorerFeaturesForConventionalSubgenresQuery,
  useGetAnalysisFeatureChoicesQuery,
  useUpdateGameMutation,
  useDeleteGameMutation,
  useCloneGameMutation,
  useGetGameLatestAnalysisQuery,
  useGetGameLatestAndPrevAnalysisQuery,
  useUpdateFeatureAnswerMutation,
  useGetRefreshAnalysisQuery,
  useGetMarketFeaturesQuery,
  useGetFeatureDemographicsAvgsQuery,
  useFinalizeAnalysisMutation,
  useGetUpdateImpactForGameVersionQuery,
  useGetMultipleGamesAndAnalysisQuery,
  useGetTrendingQuery,
  useGetExplorerDataWithGamesQuery,
  useGetExplorerDataWithFeaturesQuery,
  useGetFeatureEffectsQuery,
  useGetLatestMotivationProfileQuery,
  useGetMotivationProfileHistoryQuery,
  useGetMotivationProfileVersionsQuery,
  useGetMotivationProfileVersionQuery,
  useGetPublisherGameAppIdsQuery,
  useGetAnalysisNotesQuery,
  useUpdateAnalysisNotesMutation,
  useDeleteAnalysisNoteMutation,
  useGetMultipleGameVersionInfoListQuery,
  useGetUpdateImpactsForMultipleGamesInMarketQuery,
  useGetScreenshotCountsByGamesQuery,
  useGetExplorerGamesWithChoiceQuery,
  useGetCompareMultiGamesQuery,
  useGetTrackedGameQuery,
  useGetTrackedGamesQuery,
  useGetEventsForTrackedGamesQuery,
  useGetTrackedEventQuery,
  useGetMultipleLatestMotivationProfileQuery,
  useGetGameInnovatorListQuery,
  useGetSoftLaunchGamesQuery,
  useStartIntercomConversationQuery,
  useTutorialVideoQuery,
  useGetTutorialVideosQuery,
  useGetAllMarketFeaturesQuery,
  useGetConventionalCategoriesQuery,
  useGetBookmarksQuery,
  useUpdateBookmarkMutation,
  useDeleteBookmarkMutation,
  useCreateBookmarkMutation,
  useGetActiveFeatureListQuery,
  useGetOwnGamesCountQuery,
  useGetAnalystsMapQuery,
  useGetConceptsQuery,
  useGetConceptQuery,
  useGetActiveUsersQuery,
  usePostPublicFilesMutation,
} = coreApi

export const { useUnsubscribeDailyDigestMutation } = unauthenticatedCoreApi

export type { Category, Collection, ConceptTag, Feature, Keyword, Screenshot, SimpleFeature, Tag, TagGroup, TagGroupList }
